<template>
  <RevDialog
    :close-button-label="i18n(translations.close)"
    :name
    v-bind="$attrs"
  >
    <template #trigger="{ open }">
      <slot v-bind="{ open }" name="trigger" />
    </template>

    <template #body>
      <div class="w-auto">
        <YoutubeEmbedded class="h-[80vh]" :title :youtube-id />
      </div>
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import YoutubeEmbedded from '@backmarket/nuxt-layer-cms/YoutubeEmbedded.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDialog } from '@ds/components/Dialog'

import translations from './YoutubeVideoModal.translations'

const i18n = useI18n()

defineOptions({
  inheritAttrs: false,
})

defineProps<{ youtubeId: string; title?: string; name: string }>()
</script>
